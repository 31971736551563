import { Layout } from '@/components/Layout/Layout';
import ClientRecipesCategorySlugPage from 'components/PageLevelComponents/ClientRecipesCategorySlugPage';
import type { ISocialMediaComponent } from 'components/SocialMedia/types';
import { LOCALE_DEFAULT } from 'config/config';
import { RecipesCategoryPageController } from 'server/controller/page/recipes/categoryPageController';
import { IRecipesCategoryPageResource } from 'server/resource/page/recipesCategoryPageResource';
import { IGlobalMetaData } from 'types/GlobalMetadata';

const RootPage = (props: IRecipesCategoryPageResource) => {
  const {
    metaData,
    searchItemLabels,
    recipeCopy,
    breadcrumbsCMSData,
    footerNavData,
    footerImage,
    usercentricsIds,
    locale,
    searchOverlayData,
    countrySelectorData,
    newsletterData,
    contactUsUrl,
    difficultyCopy,
    getHeaderNav,
    getMobileNavData,
    popularSearchData,
    cardCarouselCMSData,
    recallData,
    recipeEnv,
    dynamicPageComponents,
    searchPageLabels,
    category,
    recipes,
    requiredHitsPerPage,
    followUsData,
    numberOfRecipes,
    host,
    refinementList,
    pathTranslations,
  } = props;

  return (
    <Layout
      themeName={undefined}
      pathTranslations={pathTranslations}
      locale={locale}
      globalMetadata={metaData}
      getHeaderNav={getHeaderNav}
      getMobileNavData={getMobileNavData}
      popularSearchData={popularSearchData}
      footerNavData={footerNavData}
      footerImage={footerImage}
      usercentricsIds={usercentricsIds}
      searchOverlayData={searchOverlayData}
      countrySelectorData={countrySelectorData}
      newsletterData={newsletterData}
      contactUsUrl={contactUsUrl}
      difficultyCopy={difficultyCopy as Record<string, string>}
      cardCarouselCMSData={cardCarouselCMSData}
      recallData={recallData}
    >
      <ClientRecipesCategorySlugPage
        category={category}
        dynamicPageComponents={dynamicPageComponents}
        locale={locale}
        followUsData={followUsData as unknown as ISocialMediaComponent}
        numberOfRecipes={numberOfRecipes}
        recipeCopy={recipeCopy}
        recipes={recipes}
        refinementList={refinementList}
        requiredHitsPerPage={requiredHitsPerPage}
        host={host}
        recipeEnv={recipeEnv}
        searchItemLabels={searchItemLabels as Record<string, string>}
        breadcrumbsCMSData={breadcrumbsCMSData}
        searchOverlayData={searchOverlayData}
        difficultyCopy={difficultyCopy as Record<string, string>}
        globalMetadata={metaData as IGlobalMetaData}
        searchPageLabels={searchPageLabels as Record<string, string>}
      />
    </Layout>
  );
};

type GetServerSidePropsParams = {
  locale: string;
  params: {
    slug: string;
  };
  defaultLocale: string;
  resolvedUrl: string;
  req: {
    headers: {
      host: string;
    };
  };
};

export const getServerSideProps = async ({
  locale,
  params,
  defaultLocale,
  resolvedUrl,
  req,
}: GetServerSidePropsParams) => {
  return RecipesCategoryPageController.getCachedCategoryPageData({
    resolvedUrl,
    locale: locale || defaultLocale || LOCALE_DEFAULT,
    slug: params.slug,
    host: req.headers.host,
  });
};

export default RootPage;
